import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import { AppCompanyPartnersBanner } from '../../components/AppCompanyPartnersBanner'
// import { AppCompanyReleases } from '../../components/AppCompanyReleases'
// import { AppJeffHomeBanner } from '../../components/AppJeffHomeBanner'
import { AppPartnerCathyVideo } from '../../components/AppPartnerCathyVideo'
import AppPartnersCarousalSlider from '../../components/AppPartnersCarousalSlider'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
var scroll = Scroll.animateScroll

const scrollData = {
  TotalSolution: 700,
  Security: 1000,
  ExceptionalService: 700,
  TailoredFit: 700,
  Experience: 1000,
  Quality: 1000,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const partners = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])
  return (
    <Layout>
      <RDHelmet
        subtitle={'partners'}
        contentDescription="Discover our strategic partners and collaboration opportunities to drive mutual growth."
        contentKeywords="content='Partners, Strategic alliances, Collaborations, Business partnerships, Technology partners, Ecosystem, Partner network, Joint ventures, Industry partnerships, Partner solutions'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Discover our strategic partners and collaboration opportunities to drive mutual growth."
        contentOgUrl="https://raindrop.com/about/partners"
        schemaMarkup={schemaMarkup}
      />
      <AppCompanyPartnersBanner />
      <AppPartnersCarousalSlider />

      {/* <AppJeffHomeBanner /> */}
      <AppPartnerCathyVideo />
      {/* <AppCompanyReleases /> */}
    </Layout>
  )
}

export default partners
